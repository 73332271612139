import React from "react"
import { Link } from "gatsby"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"


const NotFound = () => {
    return (
        <Layout>
            <h1>Not allowed, soz.</h1>
            <p>
                <Link to="/">Head home</Link>
            </p>
        </Layout>
    )
}

export default NotFound